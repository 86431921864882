@import '/src/theme.scss';

.SearchWrapper {
  width: 100%;
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(110, 113, 135, 0.16);
  min-height: auto;
  max-width: 410px;
}

.ScrollWrapper {
  margin: 16px 0px;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  min-height: 344px;
  max-height: 344px;
}

.ScrollWrapper::-webkit-scrollbar {
  display: none;
}

.ScrollWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.TitleRow {
  padding-left: 16px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.SearchItem.SearchItem.SearchItem {
  cursor: pointer;
  padding-left: 16px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SearchItem:hover {
  background-color: rgba(var(--mila-color-primary-rgb), 0.12);
}

.SearchItem:active {
  background-color: rgba(var(--mila-color-primary-rgb), 0.24);
}

.SearchTitle {
  font-weight: 700;
  color: $text-primary;
  font-size: 14px;
}

.TabsContainer {
  min-height: 41px;
  max-height: 41px;
  width: 100%;
  margin-top: 15px;
  max-width: 410px;
}

.TabsContainer :global(.ant-tabs) {
  width: 100%;
}

.Tabs :global(.ant-tabs-tab) {
  height: 38px;
  width: 99px;
}

.SearchWrapper :global(.ant-list) {
  box-shadow: none;
  border-radius: 0px;
  border-block-start: 1px solid rgba(5, 5, 5, 0.06);
  border-block-end: 1px solid rgba(5, 5, 5, 0.06);
}

.ListContainer {
  width: 100%;
  padding-top: 0px;
  min-height: auto;
}

.EmptyList {
  padding: 16px 0;
  width: 100%;
  min-height: auto;
  max-width: 410px;
}

.TextCol {
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.SearchRow {
  display: flex;
  align-items: center;
}

.Text {
  font-size: 14px;
  font-weight: 400;
  color: $text-primary;
}

.SmallText {
  font-size: 12px;
  font-weight: 400;
  color: $text-primary;
}

img.DoctorPhoto.DoctorPhoto {
  width: 50px;
  height: 50px;
}

.TagRow {
  gap: 5px;
}

@media screen and (max-width: 540px) {
  .TextCol {
    max-width: 235px;
    gap: 0px;
  }
}
