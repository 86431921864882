@import '/src/theme.scss';

.MenuCol {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 335px;
}

.ListItem {
  text-align: start;
  height: 48px;
  cursor: pointer;
  padding-left: 8px;
}

.ListItem:hover {
  background-color: rgba(var(--mila-color-primary-rgb), 0.12);
}

.ListItem:active {
  background-color: rgba(var(--mila-color-primary-rgb), 0.24);
}

.Wrapper {
  background-color: #F0F2F8;
  border: 1px solid $border;
  border-radius: 6px;
  overflow: hidden;
  max-width: 100vw;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.DoctorsWrapper {
  background-color: var(--mila-color-doctors-background);
  border: 1px solid var(--mila-color-doctors-border);
}

.Container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background-size: contain;
  padding-top: 20px;
  overflow: scroll;
}

.Icon {
  padding-right: 10px;
}

.OptionText {
  margin-left: 8px;
  color: $text-primary;
  font-family: 'Roboto';
}

.SearchInput {
  width: 100%;
  max-width: 410px;
}

.ListContainer {
  max-width: 410px;
  padding: 16px 0;
  width: 100%;
  min-height: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Container::-webkit-scrollbar {
  display: none;
}
.Container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.SpinnerWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 541px) {
  .Wrapper {
    position: static;
    height: 690px;
    width: 900px;
  }

  .DoctorsWrapper {
    height: 100%;
    width: 100%;
    position: fixed;
  }

  .Container {
    padding-top: 16px;
    overflow: hidden;
  }

  .MenuCol {
    width: 100%;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .Container::-webkit-scrollbar {
    display: block;
  }
  .Container {
    -ms-overflow-style: auto; /* IE and Edge */
    scrollbar-width: 10px; /* Firefox */
  }
}

@media screen and (max-width: 540px) {
  .Container {
    padding-bottom: 60px;
  }
}
