@import '/src/theme.scss';

.Header.Header {
  height: 66px;
  text-align: start;
  background-color: $white;
  border-bottom: 1px solid $page-background;
  padding: 0 36px 0 40px;
}

.HeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
}

.Clinic {
  margin-left: 16px;
  color: $text-primary;
  font-size: 16px;
}

.LogoCol {
  margin-right: 5px;
}

.BreadcrumbsRow {
  max-width: 555px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.BreadcrumbsText {
  color: $text-tertiary;
  font-size: 12px;
  line-height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;
  width: 125px;
}

.BreadcrumbsTextActive {
  color: var(--mila-color-primary);
}

.VerticalDivider {
  color: #bfc0c6;
}

.CloseButton {
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CloseButton.CloseButton.CloseButton,
.CloseButton.CloseButton.CloseButton:hover,
.CloseButton.CloseButton.CloseButton:active,
.CloseButton.CloseButton.CloseButton:focus {
  border: none;
}

@media screen and (max-width: 770px) {
  .Header.Header {
    padding: 0 12px 0 26px;
  }
  .BreadcrumbsRow {
    display: none;
  }
}

.Logo {
  max-width: 50px;
  object-fit: contain;
}
