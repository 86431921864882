@import '/src/theme.scss';

.BannerCard {
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  max-height: 120px;
}

.BannerCard :global(.ant-card-body) {
  width: 410px;
  min-height: 120px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  padding-left: 24px;
}

.BannerCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
}

.TextSmall {
  font-size: 12px;
  color: $text-secondary;
}

.TextBold {
  font-weight: 500;
  color: $text-primary;
}

.TextColored.TextColored, .TextColored.TextColored:focus {
  color: var(--mila-color-primary);
  font-size: 12px;
}

.TextColored.TextColored:hover {
  color: rgba(var(--mila-color-primary-rgb), 0.52);
}

@media screen and (max-width: 540px) {
  .BannerCard :global(.ant-card-body) {
    width: 335px;
    min-height: 130px;
  }
}
