@import '/src/theme.scss';

.Search {
  width: auto;
  border-radius: 6px;
  padding: 8px 24px;
  box-shadow: 0px 0px 10px rgba(118, 120, 134, 0.16);
  border: 1px solid transparent;
}

.Search:active,
.Search:focus,
.Search:hover,
.Search:target {
  background-color: white;
  border: 1px solid var(--mila-color-primary);
}

.Search.Search.Search:active {
  box-shadow: 0px 0px 4px var(--mila-color-primary);
}

.Active.Active.Active.Active {
  box-shadow: 0px 0px 4px var(--mila-color-primary);
  border: 1px solid var(--mila-color-primary);
}

.Cancel {
  cursor: pointer;
}

.Cancel:active {
  transform: translateY(0.5px);
  box-shadow: 0 0.5px $border;
}

.Search :global(.ant-input-clear-icon) {
  display: flex;
}

.Search :global(.ant-input.ant-input) {
  padding-left: 12px;
}
