@import '/src/theme.scss';

.List {
  background-color: $white;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(110, 113, 135, 0.16);
}

.ListContainer {
  padding: 16px 0;
  width: 100%;
  min-height: auto;
}
