@import '/src/theme.scss';

.CustomTag {
  color: var(--mila-color-primary);
  border-radius: 6px;
  background-color: rgba(var(--mila-color-primary-rgb), 0.12);
  padding: 8px 10px 8px 10px;
  margin: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

button.Active.Active.Active.Active {
  background-color: var(--mila-color-primary);
  color: $white;
}

.Grey {
  color: $text-secondary;
  border-color: $text-secondary;
}
