@import '/src/theme.scss';

.BackButton {
  display: flex;
  background-color: transparent;
  align-items: center;
  width: 90px;
}

.BackButton.BackButton.BackButton,
.BackButton.BackButton.BackButton:hover,
.BackButton.BackButton.BackButton:active,
.BackButton.BackButton.BackButton:focus {
  border: none;
}

.Label {
  margin-left: 3px;
  color: var(--mila-color-primary);
}

.BackButton[disabled],
.BackButton[disabled].BackButton[disabled]:hover,
.BackButton[disabled].BackButton[disabled]:focus,
.BackButton[disabled].BackButton[disabled]:active {
  background: transparent;
}

.BackButton[disabled] > * {
  color: $text-tertiary;
}
.BackButton[disabled] path {
  fill: $text-tertiary;
}

@media screen and (max-width: 540px) {
  .Label {
    display: none;
  }

  .BackButton {
    width: 40px;
    height: 28px;
    padding: 0px;
  }
}
