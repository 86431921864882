@import '/src/theme.scss';

.Footer.Footer {
  display: flex;
  align-items: center;
  background-color: $white;
  padding: 0 33px;
  min-height: 52px;
  border-top: 1px solid $page-background;
  box-shadow: 0 0 20px rgba(98, 102, 167, 0.2);
  z-index: 2;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.FooterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.FooterButton {
  width: 55px;
  height: 40px;
  display: flex;
  flex-direction: column;
  padding: 2px;
  align-items: center;
  border-radius: 6px;
}

.FooterButton.FooterButton.FooterButton,
.FooterButton.FooterButton.FooterButton:hover,
.FooterButton.FooterButton.FooterButton:active,
.FooterButton.FooterButton.FooterButton:focus {
  border: none;
}

.FooterButton[disabled] > * {
  color: $text-tertiary;
}

.FooterButton[disabled] circle {
  stroke: $text-tertiary;
}

.FooterButton[disabled] :global(.IconStroke) path {
  stroke: $text-tertiary;
}

.FooterButton[disabled] :global(.IconFill) path {
  fill: $text-tertiary;
}

.FooterButton[disabled],
.FooterButton[disabled].FooterButton[disabled]:hover,
.FooterButton[disabled].FooterButton[disabled]:focus,
.FooterButton[disabled].FooterButton[disabled]:active {
  background: transparent;
  border: none;
}

.FooterButton svg {
  height: 20px;
  width: 20px;
}

.Text {
  font-size: 10px;
}

@media screen and (min-width: 771px) {
  .Footer.Footer {
    display: none;
  }
}
