@import '/src/theme.scss';

.CustomTag {
  color: var(--mila-color-primary);
  border: 0.5px solid var(--mila-color-primary);
  border-radius: 100px;
  background: transparent;
  display: flex;
  align-items: center;
  margin-inline-end: 0px;
}

.Active {
  background-color: rgba(var(--mila-color-primary-rgb), 0.08);
}

.Grey {
  color: $text-secondary;
  border-color: $text-secondary;
}
