$light: #f4f4f6;
$pale-grey: #9fa2b4;

$white: white;
$primary: var(--mila-color-primary);
$text-primary: #50515b;
$text-secondary: #767886;
$text-tertiary: #bebfc8;
$page-background: #ecedf6;
$border: #dadada;
$green: #a6c763;
