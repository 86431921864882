@import '/src/theme.scss';

.AgeToggleContainer {
  border: 1px solid var(--mila-color-primary);
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  padding: 1px;
}

div.AgeToggleContainer :global(div.ant-tabs-tab-disabled.ant-tabs-tab-disabled) {
  color: $pale-grey !important;
  cursor: not-allowed !important;
}

div.AgeToggleContainer.AgeToggleContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab) {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  color: var(--mila-color-primary);
  background: transparent;
}

.AgeToggleContainer :global(.ant-tabs-nav),
.AgeToggleContainer :global(.ant-tabs-nav-wrap),
.AgeToggleContainer :global(.ant-tabs-nav-list) {
  width: 100%;
}

div.AgeToggleContainer.AgeToggleContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab-active) {
  background-color: var(--mila-color-primary);
  color: $white;
}

div.AgeToggleContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab):hover,
div.AgeToggleContainer :global(div.ant-tabs-nav-list div.ant-tabs-tab):active {
  color: var(--mila-color-primary);
}

:global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn.ant-tabs-tab-btn) {
  color: $white;
}

:global(.ant-popconfirm-buttons) {
  visibility: hidden;
}

.Active {
  background-color: var(--mila-color-primary);
  color: $white;
}

.AgeRow {
  width: 448px;
  height: 241px;
  border-radius: 6px;
  gap: 12px;
}

.AgeTag {
  height: 32px;
  width: 95px;
}

.ChildrenLabel {
  width: 100px;
  height: 38px;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 540px) {
  .AgeRow {
    max-width: 322px;
    height: auto;
  }
}
