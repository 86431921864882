@import '/src/theme.scss';

.Footer.Footer {
  display: flex;
  align-items: center;
  background-color: $white;
  padding: 0 44px 0 26px;
  min-height: 46px;
  border-top: 1px solid $page-background;
  box-shadow: 0 0 20px rgba(98, 102, 167, 0.2);
  z-index: 2;
}

.FooterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.FooterRightRow {
  gap: 25px;
}

.Text {
  color: $text-primary;
}

.FooterSmallRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.HomeButton {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  width: 99px;
  padding: 0px;
}

.HomeButton.HomeButton.HomeButton,
.HomeButton.HomeButton.HomeButton:hover,
.HomeButton.HomeButton.HomeButton:active,
.HomeButton.HomeButton.HomeButton:focus {
  border: none;
}

.HomeButton article {
  color: var(--mila-color-primary);
}

.HomeButton :global(.ant-image) {
  line-height: 18px;
  margin-right: 2px;
}

.HomeButton[disabled] > * {
  color: $text-tertiary;
}

.HomeButton[disabled] path {
  fill: $text-tertiary;
}

.HomeButton[disabled],
.HomeButton[disabled].HomeButton[disabled]:hover,
.HomeButton[disabled].HomeButton[disabled]:focus,
.HomeButton[disabled].HomeButton[disabled]:active {
  background: transparent;
  border: none;
}

button.AddressButton.AddressButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  gap: 4px;
}

.AddressButton.AddressButton.AddressButton,
.AddressButton.AddressButton.AddressButton:hover,
.AddressButton.AddressButton.AddressButton:active,
.AddressButton.AddressButton.AddressButton:focus {
  border: none;
}

.FooterIcon {
  width: 14px;
  height: 14px;
}

.PhoneNumber.PhoneNumber.PhoneNumber {
  font-size: 12px;
  color: $text-primary;
}

.TextAddress {
  color: var(--mila-color-primary);
}

@media screen and (max-width: 770px) {
  .Footer.Footer {
    display: none;
  }
}
