@import '/src/theme.scss';

.Link.Link.Link {
  color: var(--mila-color-primary);
  border-radius: 12px;
  border: 0.5px solid var(--mila-color-primary);
  padding: 2px 10px;
  width: fit-content;
  align-self: center;
  font-size: 11px;
  margin: 30px 0 40px;
}
