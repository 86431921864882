@import '/src/theme.scss';

.PhotoImage {
  border-radius: 6px;
}

.DefaultPhoto path:first-child {
  stroke: var(--mila-color-primary);
}

.DefaultPhoto path:nth-child(2),
.DefaultPhoto path:nth-child(3) {
  fill: var(--mila-color-primary);
}
