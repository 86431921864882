@import '/src/theme.scss';

.SpecialtiesContainer {
  width: 100%;
  background: none;
  min-height: auto;
  align-items: center;
}

.SpecialtyRow {
  display: flex;
  padding: 0px;
  justify-content: space-between;
  align-items: center;
}

.SpecialtiesWrapper {
  width: 100%;
  overflow: scroll;
  background: none;
  height: 215px;
  align-items: center;
}

.ListWrapper {
  width: 78%;
}

.ListContainer {
  width: 100%;
  padding-top: 0px;
  min-height: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.SpecialtiesWrapper::-webkit-scrollbar {
  display: none;
}
.SpecialtiesWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.InnerRow {
  flex-grow: 1;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.ListItem.ListItem.ListItem {
  padding: 14px 16px;
  width: 100%;
  cursor: pointer;
}

.ListItem:hover {
  background-color: rgba(var(--mila-color-primary-rgb), 0.12);
}

.ListItem:active {
  background-color: rgba(var(--mila-color-primary-rgb), 0.24);
}

.SpecialityName {
  color: $text-primary;
}

.Title {
  font-weight: 500;
  font-size: 18px;
  color: $text-primary;
}

.DoctorAmount {
  color: $text-tertiary;
}

.Price {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $text-primary;
}

.InteractiveRow {
  justify-content: space-between;
  width: 78%;
  align-items: center;
  padding-bottom: 16px;
}

.Tabs {
  width: 202px;
}

.Tabs :global(.ant-tabs-tab) {
  height: 38px;
  width: 99px;
}

.TabsContainer {
  height: 41px;
}

.BackButton {
  position: absolute;
  left: 0;
  top: 0;
}

.TitleRow {
  width: 78%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.TagContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.RightCol {
  width: 104px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.LeftCol {
  flex-grow: 1;
  display: flex;
  gap: 8px;
  margin-right: 8%;
}

.SearchInput {
  width: 236px;
}

.Ruble {
  margin-left: 4px;
}

.BranchOption {
  white-space: break-spaces;
}

.Select {
  min-width: 230px;
}

.Select :global(.ant-select-selection-item) {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 540px) {
  .SpecialtiesContainer {
    width: 335px;
  }

  .Tabs {
    width: 335px;
  }

  .Select {
    display: none;
  }

  .SearchInput {
    width: 100%;
  }

  .TitleRow {
    width: 100%;
  }

  .InteractiveRow {
    width: 100%;
    gap: 16px;
  }

  .ListWrapper {
    width: 100%;
  }

  .ListItem.ListItem.ListItem {
    padding: 14px;
  }

  .SpecialtyRow {
    padding: 0px;
  }

  .InnerRow {
    justify-content: flex-start;
  }

  .RightCol {
    width: auto;
  }

  .LeftCol {
    flex-direction: column;
    gap: 0px;
  }

  .DateTag {
    font-size: 10px;
    height: 18px;
  }
}
