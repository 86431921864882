@import '/src/theme.scss';

span.ant-radio-inner.ant-radio-inner.ant-radio-inner::after {
  background-color: var(--mila-color-primary);
  transform: scale(0.5);
}

.ant-radio-checked.ant-radio-checked span.ant-radio-inner {
  border-color: var(--mila-color-primary);
  background-color: white;
}

.ant-checkbox-checked span.ant-checkbox-inner,
.ant-btn-primary.ant-btn-primary,
.ant-btn-primary.ant-btn-primary:hover {
  background-color: var(--mila-color-primary);
  border-color: var(--mila-color-primary);
  color: white;
}

.ant-checkbox-wrapper:hover span.ant-checkbox-inner,
span.ant-checkbox-checked::after {
  border-color: var(--mila-color-primary);
}

button.ant-btn.ant-btn:hover {
  color: var(--mila-color-primary);
  border-color: var(--mila-color-primary);
  border: 1px solid;
  background-color: rgba(var(--mila-color-primary-rgb), 0.08);
}

button.ant-btn.ant-btn:hover[disabled] {
  color: rgba($text-primary, 0.38);
  border-color: rgba($text-primary, 0.12);
  background-color: transparent;
}

button.ant-btn {
  box-shadow: none;
  border: 1px solid transparent;
}

div.ant-form-item.ant-form-item {
  margin-bottom: 0px;
}

input.ant-input:hover,
.ant-picker.ant-picker:hover,
.ant-picker-focused.ant-picker-focused,
.ant-input.ant-input:focus,
.ant-input-focused,
.ant-select:not(.ant-select-disabled):hover
  .ant-select-selector.ant-select-selector.ant-select-selector.ant-select-selector,
.ant-radio-checked.ant-radio-checked::after,
.ant-radio-input:focus + .ant-radio-inner.ant-radio-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner.ant-checkbox-inner,
.ant-input-affix-wrapper.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper.ant-input-affix-wrapper:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector.ant-select-selector {
  border-color: var(--mila-color-primary);
  box-shadow: 0 0 0 2px rgba(var(--mila-color-primary-rgb), 0.02);
}

.ant-picker-today-btn.ant-picker-today-btn,
.ant-picker-now-btn.ant-picker-now-btn,
.ant-picker-today-btn.ant-picker-today-btn:hover {
  color: var(--mila-color-primary);
}

span.ant-checkbox-checked.ant-checkbox-checked.ant-checkbox-checked::after {
  visibility: hidden;
}

.IconFill path {
  fill: var(--mila-color-primary);
}

.IconRect rect {
  fill: var(--mila-color-primary);
}

.IconStroke path {
  stroke: var(--mila-color-primary);
}

.IconStroke circle {
  stroke: var(--mila-color-primary);
}

.ant-typography.ant-typography.ant-typography a {
  color: $text-secondary;
  text-decoration: underline;
}

.ant-typography.ant-typography.ant-typography a:hover {
  color: var(--mila-color-primary);
}

.ant-collapse-expand-icon {
  color: var(--mila-color-primary);
}

.ant-select .ant-select-clear {
  right: 40px;
}
